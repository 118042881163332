<script>
  import { mapGetters, mapActions } from "vuex";
  import NewsCard from "../views/main/News/NewsCard.vue";
  import { getMain, verifyAppRequireIp } from "@/common/app.main.js";
  export default {
    props: ["new"],
    components: { NewsCard },
    data() {
      return {
        tokenName: process.env.VUE_APP_TOKEN_NAME,
        show: false,
        backgroundChanged: true,
      };
    },
    computed: {
      ...mapGetters("user", { getProfile: "getProfile" }),
    },
    methods: {
      ...mapActions("skipNewsHome", { createNewSkip: "createNewSkip", getSkip: "getSkip" }),
      async goToNew(news, id) {
        const appData = getMain();
        const verifyIp = await verifyAppRequireIp(appData, this.getProfile, this.tokenName);

        if (!verifyIp) {
          Swal.fire({
            title: "Lo sentimos no se encuentra en ninguna de nuestras sedes",
            text: "Una vez te encuentres ubicado en alguna de nuestras sedes podrás acceder sin problema",
            icon: "info",
            iconColor: "#bd0909",
            confirmButtonColor: "#bd0909",
          });
          return;
        }
        const data = {
          idNew: id,
          user: this.getProfile,
        };
        this.createNewSkip(data);
        this.$router.push(`/main`).then(() => {
          this.$nextTick(() => {
            this.$store.state.news.NewsVisor = news;
            const query = `?news=${id}`;
            this.$router.push(`/main${query}`);
          });
        });
      },
      hiddenModal(id) {
        const data = {
          idNew: id,
          user: this.getProfile,
        };
        this.createNewSkip(data);
        this.$emit("hiddenModal");
      },
    },
    async mounted() {
      await Promise.all([
        new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
          this.show = true;
        }),
        new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
          this.backgroundChanged = true;
        }),
      ]);
    },
  };
</script>
<template>
  <div :class="['presentationNew', { backgroundChanged: backgroundChanged }]">
    <div class="presentationNew__new" v-for="(news, index) in this.new" :key="news.index" :index="index" @click="goToNew(news, news._id)">
      <div class="presentationNew__card">
        <div v-if="show && index === 0" class="presentationNew__skip" @click="hiddenModal(news._id)"><iconic class="presentationNew__icon" name="close" /></div>
        <NewsCard :news="news" v-if="index === 0" :index="index" class="__presentation" :block="'presentation'" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .presentationNew {
    @include Flex(column, center, center);
    position: absolute;
    top: 0;
    width: 100%;
    height: 1250px;
    z-index: 100;
    padding: 3%;
    transition: background-color 0.3s ease;
    &.backgroundChanged {
      background-color: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(5px);
    }
    &__new {
      margin-top: 45px;
    }
    &__skip {
      @include Flex();
      position: absolute;
      right: 0;
      top: -60px;
      width: 40px;
      height: 40px;
      padding: 10px;
      text-transform: uppercase;
      font-size: 14px;
      border-radius: $radius-20;
      color: #272727;
      font-family: $first_font;
      font-weight: 600;
      background: #ffffff73;
      transition: background 0.3s ease, color 0.3s ease;
    }
    &__card {
      position: relative;
      top: -260px;
    }
    &__icon {
      font-size: 20px;
    }
    @media (min-width: 1024px) {
      padding: 10%;
      height: 100%;
      transition: background-color 0.3s ease;
      &.backgroundChanged {
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(5px);
      }
      &__new {
        margin-top: -20px;
      }
      &__skip {
        &:hover {
          background: $primary-color;
          color: #fff;
          cursor: pointer;
        }
      }
      &__card {
        position: relative;
        top: 40px;
      }
      &__icon {
        font-size: 20px;
      }
    }
  }
</style>
