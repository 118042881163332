<template>
  <Recycler class="news-card" :index="index" icon="dots_loading" ref="recycler" @visible="initcard" @invisible="invisbleCard" :disabled="block === 'presentation' || block === 'mainNews'">
    <div class="news-card-wrap" :id="`card-${cardId}`" :style="`--nr-animation-transform-y:${transformValue}`">
      <div :class="`news-card-content ${cardContentClass}`" transition>
        <div class="news-card-cover">
          <NewsCardPoster :news="news" />
        </div>
        <div class="news-card-data">
          <div class="news-card-data-desc">
            <div class="news-card-title">
              <span class="text" v-if="block === 'others'">
                {{ getShortDesc(news.titleNews || "Sin texto", 24) }}
              </span>
              <span class="text" v-else>
                {{ news.titleNews || "Sin titulo" }}
              </span>
              <a class="button" @click="newsMenu(news, $event)" v-if="isAdminNews && this.$route.path !== '/apps'"><iconic name="ellipsis-v"/></a>
            </div>
            <span class="news-card-text news-card-text-subtitle">
              {{ getShortDesc(news.subtitleNews, 50) || "" }}
            </span>
            <div class="news-card-text">
              <div class="text" v-html="getShortDesc(news.textNews || 'Sin texto', index)"></div>
            </div>
          </div>
          <div class="news-card-social">
            <div class="news-card-date">
              <div>{{ $global.dateLocaleFormat(news.date) }}</div>
              <NewsIsPrivate :news="news" v-if="isNewsPrivate(news)" />
            </div>
            <div class="news-card-bottom">
              <div class="news-card-social-reactions">
                <NewsCardSocial :news="news" />
              </div>
              <div class="news-card-social-options">
                <LikesInfo :likes="news.likes" :postId="news._id" endpoint="/news/likes" :news="news" />
                <div class="news-card-status">
                  <a v-if="!isNewsArchive(news)" class="archive-button tool-tip" @click="newsMenu(news, $event)">
                    <span noevents class="tooltiptext tool-archive">Noticia Archivada</span>
                    <iconic name="folder_open" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Recycler>
</template>

<script>
  import NewsMixing from "./NewsMixing";
  export default {
    mixins: [NewsMixing],
    props: ["news", "index", "block"],
    components: {
      MarkOffer: () => import("@/components/widgets/MarkOffer"),
      Toggle: () => import("@/components/input/ToggleInput.vue"),
      LikesInfo: () => import("@/components/likes/LikeInfo.vue"),
      NewsCardPoster: () => import("./NewsCardPoster.vue"),
      NewsCardSocial: () => import("./NewsCardSocial.vue"),
      NewsCardOptions: () => import("./NewsCardOptions.vue"),
      NewsIsPrivate: () => import("./NewsIsPrivate.vue"),
    },
    computed: {
      user: function() {
        return this.$profile;
      },
      screenWidth: function() {
        return this.$screen.width;
      },
    },
    data: function() {
      return {
        transformValue: 0,
        cardId: this.$global.fastID(),
        cardContentClass: "",
      };
    },

    methods: {
      getShortDesc: function(text, index) {
        const limitScreen = this.screenWidth > 1440 ? 455 : 300;
        let textLength = index === 0 ? limitScreen : 250;
        this.block === "others" && (textLength = 85);
        this.block === "presentation" && (textLength = this.screenWidth > 1600 ? 300 : 110);
        return this.$global.getShort(text, textLength);
      },
      getPoster: function(news) {
        const emptyPoster = `https://new-web-site-bucket.s3.amazonaws.com/news/notice_empty.jpg`;
        return news.thumbnail || news.imageNews || emptyPoster;
      },
      invisbleCard: function() {
        this.cardContentClass = ``;
        this.transformValue = this.$store.state.screen.scrollDirection === "up" ? `-50%` : `50%`;
      },
      initcard: async function() {
        if (!this.cardContentClass) {
          this.transformValue = this.$store.state.screen.scrollDirection === "up" ? `-50%` : `50%`;
        }
        await this.sleep(100);
        this.cardContentClass = `__active`;
      },
    },
    mounted: async function() {
      this.initcard();
    },
  };
</script>

<style lang="scss">
  .news-card {
    display: inline-flex;
    flex-direction: column;
    width: 100%;

    &-wrap {
      display: flex;
      flex-direction: column;
      flex: auto;
    }
    &-content {
      display: flex;
      flex-direction: column;
      flex: auto;
      background-color: #fff;
      border-radius: $mpadding/1.5;
      overflow: hidden;
      position: relative;
      box-shadow: $dshadow;
      pointer-events: none;
      opacity: 0;
      &.__active {
        @include Animation(scroll-animation-card, 1.3s, 1.9s);
        pointer-events: auto;
      }
    }
    &:hover &-content {
      box-shadow: 0 0 $mpadding * 2 rgba(0, 0, 0, 0.616);
    }
    &-poster-overlay {
      cursor: pointer;
    }
    &:hover &-poster-overlay,
    &:hover .news-card-item {
      opacity: 1;
    }
    &-cover {
      width: 100%;
    }
    &-data {
      display: flex;
      flex-direction: column;
      flex: auto;
      &-desc {
        margin: 0 0 auto 0;
      }
    }
    &-title {
      padding: $mpadding $mpadding/2 $mpadding $mpadding;
      display: flex;
      justify-content: space-between;
      .text {
        font-size: 150%;
        font-family: $sec_font;
        word-wrap: break-word;
        word-break: break-word;
        line-height: 1.1;
        margin: $mpadding / 1.5 0 0 0;
        * {
          word-wrap: break-word;
          word-break: break-word;
        }
      }
      .button {
        @include ButtonCircle();
      }
      .button:hover {
        background-color: rgba(0, 0, 0, 0.171);
      }
    }
    &-text {
      padding: 0 $mpadding $mpadding $mpadding;
      text-align: justify;
      word-wrap: break-word;
      &-subtitle {
        font-size: 120%;
        font-family: $sec_font;
        margin: 0 0 $mpadding 0;
      }
    }
    &-date {
      padding: 0 $mpadding $mpadding $mpadding;
      font-size: 90%;
      font-weight: bold;
      opacity: 0.8;
      margin: 0 0 auto 0;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-social {
      margin: auto 0 0 0;
      &-reactions {
        position: relative;
        border-top: solid 1px $alto;
      }
      &-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $mpadding;
        border-top: solid 1px $alto;
      }
      .likesInfoNoLikes {
        font-size: 120%;
      }
    }
    .archive-button {
      @include ButtonCircle();
      @include Margin(0, -$mpadding/3, -$mpadding/3, 0);
      font-size: 120%;
      color: $primary_color;
    }
    .tool-tip {
      .tooltiptext {
        font-size: 80%;
        padding: $mpadding/2 $mpadding;
      }
      .tooltiptext.tool-archive {
        bottom: calc(120% - 5px);
        left: 50%;
        transform: translateX(-120%);
        border-radius: $mpadding $mpadding 0 $mpadding;
        &:after {
          display: none;
        }
      }
    }

    &:before {
      max-width: calc(100% - #{$mpadding * 2});
    }

    @include screen_desktop() {
      width: 50%;

      &[index="0"] {
        width: 100%;
        min-height: 480px !important;
      }

      &[index="0"]:hover &-poster-overlay {
        cursor: pointer;
      }

      &[index="0"] &-content {
        flex-direction: row;
      }

      &[index="0"] &-poster {
        height: 100%;
      }

      &[index="0"] &-cover {
        flex: 0.5;
      }

      &[index="0"] &-data {
        width: 50%;
        flex: 0.5;
        border-left: solid 1px $alto;
      }

      &[index="0"] &-title {
        .text {
          font-size: 250%;
          margin: $mpadding * 1.5 0 0 0;
        }
      }

      &.__other {
        .news-card-poster {
          width: 100%;
          min-width: 250px;
        }
      }
    }
    @include screen_desktop() {
      width: 100%;

      &[index="0"] &-cover {
        flex: 0.6;
      }

      &[index="0"] &-data {
        flex: 0.4;
        border-left: solid 1px $alto;
      }
    }
    @media (max-width: 430px) {
      .news-card-content {
        width: 100%;
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      &.__presentation {
        .news-card-poster {
          height: 300px;
        }
      }
    }
    @media (min-width: 1025px) and (max-width: 1919px) {
      &[index="0"] {
        width: 100%;
        max-height: 400px;
      }
      &[index="0"] &-cover {
        flex: 0.65;
      }
      &[index="0"] &-content {
        height: 400px;
        flex-direction: row;
      }
      &[index="0"] &-data {
        flex: 0.4;
        border-left: solid 1px $alto;
      }
    }
  }
</style>
